<template>
  <div class="modal-card">
    <header class="modal-card-head has-text-centered">
      <h1 class="modal-card-title">
        {{ $t("common.error_modal_title") }}
      </h1>
      <div class="modal-close-button" @click="$parent.close()">
        <close-icon class="close-icon"></close-icon>
      </div>
    </header>
    <section class="modal-card-body pt-1 modal-card-foot">
      <div class="size-6 subtitle has-text-centered" v-html="$t('auth.set_password_expired_token_error')"></div>
      <div class="columns is-centered">
        <div class="column is-half">
          <router-link
            v-if="eventId"
            :to="{ name: Constants.ROUTE_FORGOT_PASSWORD }"
            class="button is-fullwidth is-primary"
            style="margin-top: 1rem"
            @click.native="$parent.close()"
          >
            {{ $t("auth.set_password_expired_token_button") }}
          </router-link>

          <router-link
            v-else
            :to="{ name: Constants.ROUTE_FORGOT_PASSWORD_EVENTLESS }"
            class="button is-fullwidth is-primary"
            style="margin-top: 1rem"
            @click.native="$parent.close()"
          >
            {{ $t("auth.set_password_expired_token_button") }}
          </router-link>
        </div>
        <div class="column is-half" v-if="eventId && isNotEfniEvent">
          <router-link
            :to="{ name: Constants.ROUTE_LOGIN }"
            class="button is-fullwidth is-link"
            style="margin-top: 1rem"
            @click.native="$parent.close()"
          >
            {{ $t("auth.log_in") }}
          </router-link>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import CloseIcon from "@/assets/icon_close.svg";
import Constants from "@/web/constants";
import {mapState} from "vuex";

export default {
  name: "ResetPasswordErrorModal",

  components: {CloseIcon},

  computed: {
    Constants: () => Constants,
    ...mapState(["eventId"]),

    isNotEfniEvent() {
      return this.eventId !== 1185;
    },
  },
};
</script>

<style lang="scss" scoped>
.button {
  padding-left: 60px;
  padding-right: 60px;
}

.subtitle {
  white-space: pre-line;
}
</style>
